import React, { FC } from 'react';
import { useMediaQuery } from "react-responsive";
import {
    PieChart, Pie, Cell, Label, ContentRenderer, PieLabelRenderProps
} from 'recharts';

const COLORS = [ '#0088FE', '#00C49F', '#FFBB28', '#FF8042' ];

type Props = {
    cx: string | number | undefined,
    cy: string | number | undefined,
    midAngle: number,
    innerRadius: number,
    outerRadius: number,
    percent: number,
    index: number,
    name: string
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel: ContentRenderer<PieLabelRenderProps> = ( {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    name
} ) => {
    const radius = Number( innerRadius ) + ( Number( outerRadius ) - Number( innerRadius ) ) * 0.5;
    const x = Number( cx ) + radius * Math.cos( -Number( midAngle ) * RADIAN );
    const y = Number( cy ) + radius * Math.sin( -Number( midAngle ) * RADIAN );

    return (
        <text
            x={ x }
            y={ y - 8 }
            fill="rgba(0,0,0,0.75)"
            textAnchor={ x > Number( cx ) ? 'start' : 'end' }
            dominantBaseline="central">
            { `${ name } ${ ( Number( percent ) * 100 ).toFixed( 0 ) }%` }
        </text>
    );
};

type SimplePieChartProps = {
    data: {
        name: string,
        value: string|number
    }[],
    chartLabel: string
}

export const SimplePieChart: FC<SimplePieChartProps> = ( {
    data,
    chartLabel
} ) => {
    // const jsfiddleUrl = 'https://jsfiddle.net/alidingling/c9pL8k61/';
    const isTabletOrMobile = useMediaQuery( { query: '(max-width: 899px)' } )
    return (
        <PieChart width={ isTabletOrMobile ? 320 : 600 } height={ 240 }>
            <Pie
                data={ data }
                cx={ "50%" }
                cy={ "50%" }
                labelLine={ false }
                label={ renderCustomizedLabel }
                outerRadius={ 80 }
                fill="#8884d8"
                dataKey="value">
                { data.map( ( entry, index ) =>
                    <Cell
                        key={ `cell-${ index }` }
                        fill={ COLORS[ index % COLORS.length ] } />
                ) }
                <Label width={ 60 } position="center">
                    { `${ chartLabel }` }
                </Label>
            </Pie>
        </PieChart>
    );
}
