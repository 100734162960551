import React, { useState, useEffect, useMemo } from "react"
import Styles from "../styles/team_usage.module.scss";
import Layout from "../components/Layout"
import SEO from "../components/seo"
import InfoItem from "../components/InfoItem"
import { change_second_to_japanese } from "../utils/utilFunctions"
import { SimplePieChart } from "../components/PieChart"
import { SimpleBarChart } from "../components/BarChart"
import ActivityIndicator from "../components//atoms/ActivityIndicator"
import { BACK_BUTTON_STR } from "../constant/const";
import client from "../apis/client";

const headerOption = {
    headerTitle: "ご使用状況",
    leftIcon: BACK_BUTTON_STR
}

type usageType = {
    key: string
    value: number
    label: string
}

const InitialTeamUsageList: usageType[] = [
    { key: "postCount", value: 0, label: "投稿数" },
    { key: "commentCount", value: 0, label: "コメント数" },
    { key: "videoCount", value: 0, label: "投稿動画数" },
    { key: "videoDuration", value: 0, label: "総動画アップロード時間" },
]

type memberUsageType = {
    postCount: number
    commentCount: number
    videoCount: number
    last_name: string
}

const TeamUsagePage = () => {

    const [ teamPlan, setTeamPlan ] = useState( "" );
    const [ teamUsageList, setTeamUsageList ] = useState<usageType[]>( InitialTeamUsageList );
    const [ teamMemberUsageList, setTeamMemberUsageList ] = useState<memberUsageType[]>( [] );
    const [ teamVideoDurationPlanLimit, setTeamVideoDurationPlanLimit ] = useState( 0 );

    useEffect( () => {
        window.ReactNativeWebView && sendDidMountToWebView()
        fetchTeamUsageInfo()
        fetchTeamMemberUsageInfo()
    }, [] )

    const sendDidMountToWebView = () => {
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage( "didMount" );
    }

    const fetchDataFromWebView = () => {
        const userToken = window.userToken
        if ( !userToken ) return
        fetchTeamUsageInfo( userToken )
        fetchTeamMemberUsageInfo( userToken )
    }

    const fetchTeamUsageInfo = async ( userToken?: string ) => {
        const authorizationParams = userToken ?
            { AUTHORIZATION: `Token ${ userToken }` } :
            undefined
        await client.get( `/api/team/usage/`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                ...authorizationParams,
            },
        } )
            .then( res => {
                const newUsageList = teamUsageList.map( usage => ( {
                    ...usage,
                    value: usage.key === "videoDuration" ?
                        change_second_to_japanese( res.data[ usage.key ] ) :
                        res.data[ usage.key ]
                } ) )
                setTeamUsageList( newUsageList )
                setTeamPlan( res.data.teamPlan )
                setTeamVideoDurationPlanLimit( res.data.teamVideoDurationPlanLimit )
            } )
            .catch( error => console.log( error ) )
    }

    const fetchTeamMemberUsageInfo = async ( userToken?: string ) => {
        const authorizationParams = userToken ?
            { AUTHORIZATION: `Token ${ userToken }` } :
            undefined
        await client.get( `/api/team/member_usage/`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                ...authorizationParams,
            },
        } )
            .then( res => {
                setTeamMemberUsageList( res.data.count_list as memberUsageType[] )
            } )
            .catch( error => console.log( error ) )
    }

    const needsLargeChart = 2 < teamMemberUsageList.length
    const barChartWidth = needsLargeChart ? 1000 : 720

    const BarChartMemo = useMemo( () => {

        const dataForBar = teamMemberUsageList.map( memberUsage => ( {
            ...memberUsage,
            name: memberUsage.last_name
        } ) )

        const dataMetaListForBar = [
            { key: "postCount", label: "投稿数", color: Styles.main_color },
            { key: "commentCount", label: "コメント数", color: Styles.main_color },
            { key: "videoCount", label: "動画数", color: Styles.accent_color },
        ]
        return (
            teamMemberUsageList.length ?
                <div
                    className={ Styles.bar_chart_wrapper }>
                    <SimpleBarChart
                        data={ dataForBar }
                        dataMetaList={ dataMetaListForBar }
                        width={ barChartWidth } />
                </div> :
                <ActivityIndicator />
        )
    }, [ teamMemberUsageList, barChartWidth ] )

    const PieChartMemo = useMemo( () => {
        const dataForPie = [
            { name: "使用", value: teamUsageList.filter( usage => usage.key === "videoDuration" )[ 0 ].value },
            { name: "未使用", value: teamVideoDurationPlanLimit }
        ]
        return (
            teamPlan ?
                <SimplePieChart
                    data={ dataForPie }
                    chartLabel={ `プラン ${ teamPlan }` } /> :
                <ActivityIndicator />
        )
    }, [ teamUsageList, teamVideoDurationPlanLimit, teamPlan ] )

    return (
        <Layout headerOption={ headerOption }>
            <>
                <SEO title="TeamUsage" />
                <h1 className={ Styles.main_heading }>今月の使用状況</h1>
                <button style={ { display: "none" } } onClick={ fetchDataFromWebView } id="for_webview_data_fetch_button" />
                <div className={ Styles.container }>
                    <h2 className={ Styles.heading }>
                        メンバーごとの使用状況
                        <span style={ { color: "rgba(0,0,0,0.54)" } }> (公開されているもののみ)</span>
                    </h2>
                    { BarChartMemo }
                    <h2 className={ Styles.heading }>チームでの使用状況</h2>
                    <InfoItem
                        key="plan"
                        value={ teamPlan }
                        label="プラン"
                    />
                    { teamUsageList.map( usage =>
                        <InfoItem
                            key={ usage.key }
                            value={ usage.value }
                            label={ usage.label } />
                    ) }
                    <InfoItem
                        key="planLimit"
                        value={ change_second_to_japanese( teamVideoDurationPlanLimit ) }
                        label="プラン上限時間" />
                    <h2 className={ Styles.heading }>動画アップロード時間の割合</h2>
                    { PieChartMemo }
                </div>
            </>
        </Layout> )
}

export default TeamUsagePage
