import React, { FC } from "react"
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

type dataMeta = {
    key: string
    color: string
    label: string
}

type dataTypes = {
    key: string
    label: string
    value: string
}

type Props = {
    data: any[],
    dataMetaList: dataMeta[],
    width: number
}

export const SimpleBarChart: FC<Props> = ( {
    data,
    dataMetaList,
    width
} ) => {

    const renderCustomAxisTick = ( {
        x,
        y,
        payload
    }: { x: number, y: number, payload: { value: string } } ) => {
        return (
            <text
                x={ x }
                y={ y + 16 }
                width={ 12 }
                fill="#666"
                writing-mode="tb">
                { payload.value }
            </text> )
    }

    return (
        <BarChart
            width={ width }
            height={ 300 }
            data={ data }
            margin={ {
                top: 8, right: 16, left: 0, bottom: 64,
            } }>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" tick={ renderCustomAxisTick } interval={ 0 } />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="top" height={ 32 } />
            { dataMetaList.map( dataMeta =>
                <Bar
                    key={ dataMeta.key }
                    dataKey={ dataMeta.key }
                    fill={ dataMeta.color }
                    name={ dataMeta.label } />
            ) }
        </BarChart>
    );
}
